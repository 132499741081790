export interface FileManager {
  status: Status;
  obligatorio?: boolean;
  fileInfo: FileInfo;
  input: InputField;
  input2?: InputField;
  actions: Actions;
  first?: boolean;
  troncal?: boolean;
  expandir?: boolean;
  validaciones?: any;
  size?: any;
  tamanio?: any;
}

export enum Status {
  NUEVO = 'NUEVO',
  VALIDADO = 'VALIDADO',
  VIGENTE = 'VIGENTE',
  VENCIDO = 'VENCIDO',
  DISPONIBLE = 'DISPONIBLE',
  NODISPONIBLE = 'NODISPONIBLE',
  DEVUELTO = 'DEVUELTO',
  OBLIGATORIO = 'OBLIGATORIO',
  OPCIONAL = 'OPCIONAL',
  CARGADO = 'CARGADO',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  ENPROCESO = 'ENPROCESO',
  REVISARACTUALIZACION = 'REVISARACTUALIZACION',
  RECHAZADO = 'RECHAZADO',
}

export interface FileInfo {
  name: string;
  uploaded: string;
  troncal?: boolean;
}

export interface InputField {
  type: string;
  label: string;
}

export interface Actions {
  motivo?: boolean;
  validar?: boolean;
  ver?: boolean;
  descargar?: boolean;
  subir?: boolean;
  expandir?: boolean;
}
